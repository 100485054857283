import * as Base from "Everlaw/Base";
import * as ColorUtil from "Everlaw/ColorUtil";
import Recipient from "Everlaw/Recipient";
import { ColorTokens, EverColor } from "design-system";
import User from "Everlaw/User";
import { Color } from "Everlaw/ColorUtil";

export class MinimalOrganization extends Base.Object implements Base.Colored, Recipient {
    override id: OrganizationId;
    name: string;

    constructor(params: MinimalOrganizationParams) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: MinimalOrganizationParams): void {
        this.name = params.name;
    }

    get className(): string {
        return "MinimalOrganization";
    }

    override display(): string {
        return this.name;
    }

    getColorHex(): EverColor {
        if (this.isTheEverlawOrg()) {
            return ColorTokens.EVERLAW;
        }
        return ColorUtil.randomColorForId(this.id);
    }

    getColor(): Color {
        return Color.fromEverColor(this.getColorHex());
    }

    sid(): string {
        return "ORG_" + this.id;
    }

    recipientType(): string {
        return "organization";
    }

    /**
     * See `Organization#isTheEverlawOrg` on the backend.
     */
    isTheEverlawOrg(): boolean {
        return this.name === "Everlaw";
    }

    isTheUnassignedDatabasesOrg(): boolean {
        return this.name === "Unassigned Databases";
    }

    /**
     * This method can be used for comparisons between MinimalOrganizations and Organizations to
     * check whether they refer to the same org.
     */
    isSameOrg(other: MinimalOrganization | null): boolean {
        return this.id === other?.id;
    }

    /**
     * In share/notifications, only show orgs that are primary orgs of one of the possible users.
     * (It only shares with users that have access to the project anyway, but we don't want
     * to expose all orgs users belong to - only their chosen primary org.)
     */
    isSharable(users: User[]): boolean {
        if (this.isTheEverlawOrg()) {
            return false;
        }
        return users.some((user) => user.primaryOrg?.isSameOrg(this));
    }
}

/**
 * Separate class for parent organizations to distinguish them from regular organizations. Parent
 * organization objects are functionally the same as normal organization objects.
 */
export class ParentOrganization extends MinimalOrganization {
    override get className(): string {
        return "ParentOrganization";
    }
}

export type OrganizationId = number & Base.Id<"Organization">;

export interface MinimalOrganizationParams {
    id: number;
    name: string;
}

export const internalOrgIds = new Set(JSP_PARAMS.Organization?.internalOrgIds ?? []);

if (JSP_PARAMS.Organization?.minimal) {
    Base.set(MinimalOrganization, JSP_PARAMS.Organization.minimal);
}
