import Base = require("Everlaw/Base");
import Bugsnag = require("Everlaw/Bugsnag");
import Dom = require("Everlaw/Dom");
import { OrganizationId } from "Everlaw/MinimalOrganization";

class DatabaseField extends Base.Object {
    static EVERLAW_ACCESS_RESTRICTIONS = "Everlaw access restrictions";
    static EVERLAW_ACCESS_RESTRICTIONS_CJIS_VALUE = "CJIS";
    static EVERLAW_ACCESS_RESTRICTIONS_INFO =
        "Informs Everlaw users that this database contains data with special access requirements";
    get className() {
        return "DatabaseField";
    }
    // Used by the single select field in frontend to denote it doesn't have a value selected.
    static NO_VALUE = -1;
    override id: DatabaseField.Id;
    name: string;
    organizationId: OrganizationId;
    visibility: DatabaseField.Visibility;
    type: DatabaseField.Type;
    // Contains information like options for single or multi select, or precision for datetime.
    json: DatabaseField.FieldJson;
    isRequired: boolean;
    isPinned: boolean;
    // See DatabaseField.java for an explanation of how rank is used for sorting.
    rank: string;
    constructor(params: DatabaseField.Params) {
        super(params);
        this._mixin(params);
    }
    // Human-readable display of this field's type
    typeDisplay() {
        if (this.type === DatabaseField.Type.DATE) {
            return "Date";
        } else if (this.type === DatabaseField.Type.MULTI_SELECT) {
            return "Multi-select dropdown";
        } else if (this.type === DatabaseField.Type.NUMBER) {
            return "Number";
        } else if (this.type === DatabaseField.Type.SINGLE_SELECT) {
            return "Single-select dropdown";
        } else if (this.type === DatabaseField.Type.TEXT) {
            return "Text field";
        }
        Bugsnag.notify(Error(`Unknown database field type for field ${this.id}: ${this.type}`));
        return "Unknown type";
    }
    // Human-readable display of this field's visibility
    visibilityDisplay(): Dom.Content {
        if (this.visibility === DatabaseField.Visibility.ALL_USERS) {
            return Dom.div(
                Dom.div("All users"),
                Dom.div(
                    { class: "caption-text" },
                    "Legal holds organization admins, database admins, and project users will only see this information if the field is pinned",
                ),
            );
        } else if (
            this.visibility === DatabaseField.Visibility.ORG_AND_LEGAL_HOLD_ORG_AND_DB_ADMINS
        ) {
            return Dom.div(
                Dom.div("Organization, legal holds organization, and database admins"),
                Dom.div(
                    { class: "caption-text" },
                    "Legal holds organization admins and database admins will only see this information if the field is pinned",
                ),
            );
        } else if (this.visibility === DatabaseField.Visibility.ORG_AND_LEGAL_HOLD_ORG_ADMINS) {
            return Dom.div(
                Dom.div("Organization and legal holds organization admins only"),
                Dom.div(
                    { class: "caption-text" },
                    "Legal holds organization admins will only see this information if the field is pinned",
                ),
            );
        }
        Bugsnag.notify(
            Error(`Unknown database field visibility for field ${this.id}: ${this.visibility}`),
        );
        return Dom.div("Unknown visibility.");
    }
    isSingleOrMultiSelect() {
        return (
            this.type === DatabaseField.Type.SINGLE_SELECT
            || this.type === DatabaseField.Type.MULTI_SELECT
        );
    }
    isEverlawAccessRestriction() {
        return this.name === DatabaseField.EVERLAW_ACCESS_RESTRICTIONS;
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }
    override display() {
        return this.name;
    }
}

module DatabaseField {
    export type Id = number & Base.Id<"DatabaseField">;

    export enum Visibility {
        ALL_USERS = "ALL_USERS",
        ORG_AND_LEGAL_HOLD_ORG_AND_DB_ADMINS = "ORG_AND_LEGAL_HOLD_ORG_AND_DB_ADMINS",
        ORG_AND_LEGAL_HOLD_ORG_ADMINS = "ORG_AND_LEGAL_HOLD_ORG_ADMINS",
    }

    export enum Type {
        TEXT = "TEXT",
        SINGLE_SELECT = "SINGLE_SELECT",
        MULTI_SELECT = "MULTI_SELECT",
        NUMBER = "NUMBER",
        DATE = "DATE",
    }

    export type Option = {
        id: number;
        text: string;
    };

    export type SelectJson = {
        options: Option[];
        nextOptionId: number;
    };

    export type DateTimeJson = {
        precision: number;
    };

    export type FieldJson = SelectJson | DateTimeJson;

    export const MAX_PINNED_FIELDS = 3;

    export interface Params {
        id: DatabaseField.Id;
        name: string;
        organizationId: OrganizationId;
        visibility: DatabaseField.Visibility;
        type: DatabaseField.Type;
        isRequired: boolean;
        isPinned: boolean;
        rank: string;
        json?: DatabaseField.FieldJson;
    }
}

export = DatabaseField;
